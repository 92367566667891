.invisible {
  opacity: 0;
}
.fade1 {
  opacity: 0;
  animation: fade1 2s;
}
.fade2 {
  opacity: 0;
  animation: fade2 2s;
}

@keyframes fade1 {
  /* You could think of as "step 1" */
  0% {
    opacity: 1;
  }
  /* You could think of as "step 2" */
  100% {
    opacity: 0;
  }
}

@keyframes fade2 {
  /* You could think of as "step 1" */
  0% {
    opacity: 1;
  }
  /* You could think of as "step 2" */
  100% {
    opacity: 0;
  }
}
